// middleware/auth.ts
import { Buffer } from 'buffer'
import { ofetch } from 'ofetch'
export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.server) return

  const store = useAuthStore()
  const { getAccessToken, getRefreshToken } = storeToRefs(store)

  if (getAccessToken.value) {
    const base64Url = getAccessToken.value.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const decodedToken = JSON.parse(Buffer.from(base64, 'base64').toString())

    if (decodedToken && decodedToken.exp * 1000 < Date.now()) {
      const err = await store.refreshToken()

      if (err) {
        return navigateTo('/signup')
      }
    }
  } else if (getRefreshToken.value) {
    const err = await store.refreshToken()

    if (err) {
      return navigateTo('/signup')
    }
  }
  if (getAccessToken.value) {
    return navigateTo('/account')
  }
})
